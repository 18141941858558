import React, { useContext } from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import { Stack, Text, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import Spinner from '@atlaskit/spinner';
import SectionMessage from '@atlaskit/section-message';
import { useThemeObserver } from '@atlaskit/tokens';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';

import NotionDataFileIcon from '../images/NotionFileIcon.png';
import NotionDataFileIconDark from '../images/NotionFileIconDark.png';
import MapNotionMembersIcon from '../images/mapNotionMembersIcon.png';
import MapNotionMembersIconDark from '../images/mapNotionMembersIconDark.png';
import DontMapNotionMembersIcon from '../images/dontMapNotionMembersIcon.png';
import DontMapNotionMembersIconDark from '../images/dontMapNotionMembersIconDark.png';
import AddRemainingMembersIcon from '../images/addRemainingMembersIcon.png';
import AddRemainingMembersIconDark from '../images/addRemainingMembersIconDark.png';
import DontAddRemainingMembersIcon from '../images/dontAddRemainingMembersIcon.png';
import DontAddRemainingMembersIconDark from '../images/dontAddRemainingMembersIconDark.png';
import CopyPagePermissionsIcon from '../images/copyPagePermissionsIcon.png';
import DontCopyPagePermissionsIcon from '../images/dontCopyPagePermissionsIcon.png';
import CreateNewSpaceIcon from '../images/createNewSpaceIcon.png';
import CreateNewSpaceIconDark from '../images/createNewSpaceIconDark.png';
import DontCreateNewSpaceIcon from '../images/dontCreateNewSpaceIcon.png';
import DontCreateNewSpaceIconDark from '../images/dontCreateNewSpaceIconDark.png';
import { ReviewStepCard } from '../ImportContentDrawer/ReviewStepCard';
import { ImportContentWorkflowContext } from '../ImportContentDrawer/ImportContentStore';
import { PermissionOptions } from '../PermissionOptions';
import { translations as connectDataTranslations } from '../ImportContentDrawer/NotionOauth/translations';
import { truncate, formatBytes } from '../fileUtils';

const i18n = defineMessages({
	title: {
		id: 'import-content.review-details-step.title',
		defaultMessage: 'Review summary',
		description:
			'Title of the review step in a flow that will help the user set up a Confluence space from imported data',
	},
	descriptionOne: {
		id: 'import-content.review-details-step.description-one',
		defaultMessage: 'Confirm or correct the details below before starting the import.',
		description:
			'First half of the description at the top of the review step of a flow where a user is importing Notion data into Confluence',
	},
	descriptionTwo: {
		id: 'import-content.review-details-step.description-two',
		defaultMessage:
			'Most formatting will be preserved with some exceptions. For example, databases will be imported as static tables. <a>See what’s supported</a>',
		description:
			'Second half of the description at the top of the review step of a flow where a user is importing Notion data into Confluence',
	},
	legalDisclaimer: {
		id: 'import-content.review-details-step.legal-disclaimer',
		defaultMessage:
			'This feature relies on third party products, and Atlassian does not guarantee their availability, performance, or operation. By selecting <b>Start import</b>, you agree that you have the proper rights, authorizations and consents to import the selected content into Confluence.',
		description:
			'Legal disclaimer to inform customers that this process relies on third-party products',
	},
	uploading: {
		id: 'import-content.review-details-step.uploading',
		defaultMessage: 'Please wait while your file is being uploaded.',
		description:
			'Some text to indicate that the file that the user has selected is still in the process of uploading',
	},
	uploaded: {
		id: 'import-content.review-details-step.uploaded',
		defaultMessage: 'Upload is complete. You can start the import now.',
		description:
			'Some text to indicate that the file that the user has selected has been uploaded and they may proceed with the import.',
	},
	dataFile: {
		id: 'import-content.review-details-step.data-file',
		defaultMessage: 'Data file: {fileSize}',
		description: 'Some text that displays the file size of a file that a user has uploaded.',
	},
	dontTransferNotionMembersTitle: {
		id: 'import-content.review-details-step.dont-transfer-notion-members-title',
		defaultMessage: 'Don’t transfer Notion members',
		description:
			'Title of a card that indicates that the user has selected to not transfer Notion members to Confluence as part of their import of Notion data into Confluence',
	},
	transferNotionMembersSubtext: {
		id: 'import-content.review-details-step.transfer-notion-members-subtext',
		defaultMessage: 'Adds their names to pages, comments, and @mentions',
		description:
			'Subtext of a card that indicates that the user has selected to transfer Notion members to Confluence as part of their import of Notion data into Confluence',
	},
	dontTransferNotionMembersSubtext: {
		id: 'import-content.review-details-step.dont-transfer-notion-members-subtext',
		defaultMessage:
			'Pages and comments will list you as the author. User @mentions will convert to plain text.',
		description:
			'Subtext of a card that indicates that the user has selected to transfer Notion members to Confluence as part of their import of Notion data into Confluence',
	},
	inviteNewMembersTitle: {
		id: 'import-content.review-details-step.invite-new-members-title',
		defaultMessage: 'Invite members who aren’t in Confluence',
		description:
			'Title of a card that indicates that the user has selected to invite non-Confluence members to Confluence as part of their import of Notion data into Confluence',
	},
	dontInviteNewMembersTitle: {
		id: 'import-content.review-details-step.dont-invite-new-members-title',
		defaultMessage: 'Don’t invite workspace members who aren’t in Confluence',
		description:
			'Title of a card that indicates that the user has selected to not invite non-Confluence members to Confluence as part of their import of Notion data into Confluence',
	},
	inviteNewMembersSubtext: {
		id: 'import-content.review-details-step.invite-new-members-subtext',
		defaultMessage:
			'Adds their names to pages, comments, and @mentions whether they join or not. May add more users to your subscription.',
		description:
			'Subtext of a card that indicates that the user has selected to invite non-Confluence members to Confluence as part of their import of Notion data into Confluence',
	},
	dontInviteNewMembersSubtext: {
		id: 'import-content.review-details-step.dont-invite-new-members-subtext',
		defaultMessage:
			'Their pages and comments will list you as the author. User @mentions will convert to plain text.',
		description:
			'Subtext of a card that indicates that the user has selected to not invite non-Confluence members to Confluence as part of their import of Notion data into Confluence',
	},
	copyPagePermissionsTitle: {
		id: 'import-content.review-details-step.copy-page-permissions-title',
		defaultMessage: 'Copy Notion page permissions',
		description:
			'Title of a card that indicates that the user has selected to copy page permissions to Confluence as part of their import of Notion data into Confluence',
	},
	dontCopyPagePermissionsTitle: {
		id: 'import-content.review-details-step.dont-copy-page-permissions-title',
		defaultMessage: 'Don’t copy Notion page permissions',
		description:
			'Title of a card that indicates that the user has selected to not copy page permissions to Confluence as part of their import of Notion data into Confluence',
	},
	copyPagePermissionsSubtext: {
		id: 'import-content.review-details-step.copy-page-permissions-subtext',
		defaultMessage: 'Preserve page-level permissions from Notion',
		description:
			'Subtext of a card that indicates that the user has selected to copy page permissions to Confluence as part of their import of Notion data into Confluence',
	},
	dontCopyPagePermissionsSubtext: {
		id: 'import-content.review-details-step.dont-copy-page-permissions-subtext',
		defaultMessage: 'All pages will be set to “Anyone in the space can view or edit”',
		description:
			'Subtext of a card that indicates that the user has selected to not copy page permissions to Confluence as part of their import of Notion data into Confluence',
	},
	createNewSpaceTitle: {
		id: 'import-content.review-details-step.create-new-space-title',
		defaultMessage: 'Create a new Confluence space “{spaceName}”',
		description:
			'Title of a card that indicates that the user has selected to create a new space as part of their import of Notion data into Confluence',
	},
	mapNotionSpaceTitle: {
		id: 'import-content.review-details-step.map-notion-space-title',
		defaultMessage: 'Each Notion teamspace will become a Confluence space',
		description:
			'Title of a card that indicates that the user has selected to map their Notion teamspaces to Confluence spaces as part of their import of Notion data into Confluence',
	},
	restrictedSpacePermissions: {
		id: 'import-content.review-details-step.restricted-space-permissions',
		defaultMessage: 'Space permissions: Restricted to you',
		description:
			'Subtext of a card that indicates that the user has selected their space permissions to be restricted as part of their import of Notion data into Confluence',
	},
	notionSpacePermissions: {
		id: 'import-content.review-details-step.notion-space-permissions',
		defaultMessage: 'Space permissions: Copy from Notion',
		description:
			'Subtext of a card that indicates that the user has selected their space permissions to be copied from Notion as part of their import of Notion data into Confluence',
	},
	defaultSpacePermissions: {
		id: 'import-content.review-details-step.default-space-permissions',
		defaultMessage: 'Space permissions: Default',
		description:
			'Subtext of a card that indicates that the user has selected their space permissions to be default as part of their import of Notion data into Confluence',
	},
	freePlanInfoBoxOne: {
		id: 'import-content.review-details-step.free-plan-info-box-one',
		defaultMessage:
			'You are on a Free plan. If this import exceeds the <b>10 user limit,</b> we will initiate a free 14-day Standard trial for you.',
		description:
			'First half of the text of an info box that is displayed when the user is detected to be on a Free plan. It informs the user that if their import of Notion data to Confluence exceeds certain thresholds, a trial will be started for them',
	},
	freePlanInfoBoxTwo: {
		id: 'import-content.review-details-step.free-plan-info-box-two',
		defaultMessage:
			'You may need to clean up storage manually if you exceed the <b>2GB storage limit.</b>',
		description:
			'Second half of the text of an info box that is displayed when the user is detected to be on a Free plan. It informs the user that if their import of Notion data to Confluence exceeds certain thresholds, they may need to clean up storage',
	},
});

const getSpacePermissionsText = (permissions: PermissionOptions) => {
	switch (permissions) {
		case PermissionOptions.RESTRICTED:
			return i18n.restrictedSpacePermissions;
		case PermissionOptions.NOTION:
			return i18n.notionSpacePermissions;
		case PermissionOptions.DEFAULT:
			return i18n.defaultSpacePermissions;
	}
};

const getMapNotionMembersIcon = (shouldMapNotionMembers: boolean, isDarkMode: boolean) => {
	if (shouldMapNotionMembers) {
		return isDarkMode ? MapNotionMembersIconDark : MapNotionMembersIcon;
	} else {
		return isDarkMode ? DontMapNotionMembersIconDark : DontMapNotionMembersIcon;
	}
};

const getAddRemainingMembersIcon = (shouldAddRemainingMembers: boolean, isDarkMode: boolean) => {
	if (shouldAddRemainingMembers) {
		return isDarkMode ? AddRemainingMembersIconDark : AddRemainingMembersIcon;
	} else {
		return isDarkMode ? DontAddRemainingMembersIconDark : DontAddRemainingMembersIcon;
	}
};

const getCreateNewSpaceIcon = (shouldCreateNewSpace: boolean, isDarkMode: boolean) => {
	if (shouldCreateNewSpace) {
		return isDarkMode ? CreateNewSpaceIconDark : CreateNewSpaceIcon;
	} else {
		return isDarkMode ? DontCreateNewSpaceIconDark : DontCreateNewSpaceIcon;
	}
};

export const ReviewDetailsStep: FC = () => {
	const context = useContext(ImportContentWorkflowContext);
	const { edition } = useSessionData();
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<Stack space="space.400" xcss={xcss({ width: '640px' })}>
			<Stack space="space.200">
				<Heading size="large">
					<FormattedMessage {...i18n.title} />
				</Heading>
				<FormattedMessage {...i18n.descriptionOne} />
				<FormattedMessage
					{...i18n.descriptionTwo}
					values={{
						a: (words: string) => (
							<Link
								href="https://support.atlassian.com/jira-software-cloud/docs/faq-import-data-from-notion-into-confluence/#What-content-gets-transferred"
								target="_blank"
							>
								{words}
							</Link>
						),
					}}
				/>
				<Text size="small">
					<FormattedMessage
						{...i18n.legalDisclaimer}
						values={{ b: (words: string) => <b>{words}</b> }}
					/>
				</Text>
			</Stack>
			<Stack space="space.100">
				<Heading size="xxsmall">Notion data file</Heading>
				<Stack space="space.200">
					<ReviewStepCard
						imageSrc={isDarkMode ? NotionDataFileIconDark : NotionDataFileIcon}
						title={formatMessage(i18n.dataFile, {
							fileSize: formatBytes(context.fileSizeBytes),
						})}
						subtitle={truncate(context.fileNameDisplay)}
					/>
					{context.isUploadingFile ? (
						<SectionMessage appearance="warning" icon={Spinner}>
							<FormattedMessage {...i18n.uploading} />
						</SectionMessage>
					) : (
						<SectionMessage appearance="success">
							<FormattedMessage {...i18n.uploaded} />
						</SectionMessage>
					)}
				</Stack>
			</Stack>
			<Stack space="space.100">
				<Heading size="xxsmall">Import settings</Heading>
				<ReviewStepCard
					imageSrc={getMapNotionMembersIcon(context.shouldMapNotionMembers, isDarkMode)}
					title={
						context.shouldMapNotionMembers
							? formatMessage(connectDataTranslations.mapNotionMembersToggleText)
							: formatMessage(i18n.dontTransferNotionMembersTitle)
					}
					subtitle={
						context.shouldMapNotionMembers
							? formatMessage(i18n.transferNotionMembersSubtext)
							: formatMessage(i18n.dontTransferNotionMembersSubtext)
					}
				/>
				{context.shouldMapNotionMembers && (
					<ReviewStepCard
						imageSrc={getAddRemainingMembersIcon(context.shouldAddRemainingMembers, isDarkMode)}
						title={
							context.shouldAddRemainingMembers
								? formatMessage(i18n.inviteNewMembersTitle)
								: formatMessage(i18n.dontInviteNewMembersTitle)
						}
						subtitle={
							context.shouldAddRemainingMembers
								? formatMessage(i18n.inviteNewMembersSubtext)
								: formatMessage(i18n.dontInviteNewMembersSubtext)
						}
					/>
				)}
				{context.shouldMapNotionMembers && (
					<ReviewStepCard
						imageSrc={
							context.shouldCopyPagePermissions
								? CopyPagePermissionsIcon
								: DontCopyPagePermissionsIcon
						}
						title={
							context.shouldCopyPagePermissions
								? formatMessage(i18n.copyPagePermissionsTitle)
								: formatMessage(i18n.dontCopyPagePermissionsTitle)
						}
						subtitle={
							context.shouldCopyPagePermissions
								? formatMessage(i18n.copyPagePermissionsSubtext)
								: formatMessage(i18n.dontCopyPagePermissionsSubtext)
						}
					/>
				)}
				<ReviewStepCard
					imageSrc={getCreateNewSpaceIcon(!!context.spaceName, isDarkMode)}
					title={
						context.spaceName
							? formatMessage(i18n.createNewSpaceTitle, {
									spaceName: context.spaceName,
								})
							: formatMessage(i18n.mapNotionSpaceTitle)
					}
					subtitle={formatMessage(getSpacePermissionsText(context.selectedPermissions))}
				/>
			</Stack>

			{edition === ConfluenceEdition.FREE && (
				<SectionMessage>
					<Stack space="space.200">
						<FormattedMessage
							{...i18n.freePlanInfoBoxOne}
							values={{ b: (words: string) => <b>{words}</b> }}
						/>
						<FormattedMessage
							{...i18n.freePlanInfoBoxTwo}
							values={{ b: (words: string) => <b>{words}</b> }}
						/>
					</Stack>
				</SectionMessage>
			)}
		</Stack>
	);
};
