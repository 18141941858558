import React from 'react';
import type { FC } from 'react';

import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';

type ReviewStepCardProps = {
	imageSrc: string;
	title: string;
	subtitle: string;
	iconBeforeSubtitle?: React.ReactChild;
};

export const ReviewStepCard: FC<ReviewStepCardProps> = ({
	imageSrc,
	title,
	subtitle,
	iconBeforeSubtitle,
}) => {
	return (
		<Flex
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				width: '100%',
				paddingRight: 'space.300',
				boxShadow: 'elevation.shadow.raised',
				borderRadius: 'border.radius.100',
			})}
		>
			<Box
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					backgroundColor: 'elevation.surface.sunken',
					width: 'size.600',
				})}
				padding="space.100"
			>
				<Image src={imageSrc} />
			</Box>

			<Stack
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					width: '100%',
					paddingLeft: 'space.300',
				})}
				space="space.100"
				alignBlock="center"
			>
				<Heading size="small">{title}</Heading>

				<Flex
					gap="space.050"
					alignItems="center"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({ color: 'color.text.subtle' })}
				>
					{iconBeforeSubtitle}
					<div>{subtitle}</div>
				</Flex>
			</Stack>
		</Flex>
	);
};
