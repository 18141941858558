import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ImportContentWorkflowContext } from '../ImportContentStore';
import type { OauthMessage } from '../../ImportNotionContentSteps/ConnectDataStep';

import { translations } from './translations';
import { getAuthorizationUrl } from './notion';

type NotionLoginButtonProps = {
	setOauthData: (oauthData: OauthMessage) => void;
	setOauthError: (isError: boolean) => void;
};

const NotionLoginButton: FC<NotionLoginButtonProps> = ({ setOauthData, setOauthError }) => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [oauthWindow, setOauthWindow] = useState<Window | null>(null);

	useEffect(() => {
		const handleMessage = (event: MessageEvent<OauthMessage>) => {
			if (event.data.message == 'oauth_error') {
				setOauthError(true);
				oauthWindow?.close();

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'sendTrackEvent',
						actionSubject: 'notionErrorFallback',
						source: 'notionLogin',
					},
				}).fire();
			}

			if (event.data.message == 'oauth') {
				// store oauth data
				setOauthData(event.data);
				context.setIntegrationToken(event.data.accessToken);

				// close popup
				setOauthWindow(null);

				// remove event listener
				window.removeEventListener('message', handleMessage);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	});

	return (
		<Button
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'loginToNotionButton',
						source: 'importContentTransferUsers',
					},
				}).fire();

				const oauthWindow = window.open(getAuthorizationUrl());
				setOauthWindow(oauthWindow);
			}}
			appearance="primary"
		>
			<FormattedMessage {...translations.loginToNotionButtonText} />
		</Button>
	);
};

export { NotionLoginButton };
