import React, { Fragment, useContext, useCallback } from 'react';
import type { ChangeEvent, FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, Grid, Stack, xcss } from '@atlaskit/primitives';
import Form, { ErrorMessage, Field } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import TextField from '@atlaskit/textfield';
import { useThemeObserver } from '@atlaskit/tokens';

import EntireWorkspaceGraphic from '../images/entireWorkspaceGraphic.png';
import EntireWorkspaceGraphicDark from '../images/entireWorkspaceGraphicDark.png';
import PageWithSubpagesGraphic from '../images/pageWithSubpagesGraphic.png';
import PageWithSubpagesGraphicDark from '../images/pageWithSubpagesGraphicDark.png';
import { PermissionsSelector } from '../ImportContentDrawer/PermissionsSelector';
import { ImportContentWorkflowContext } from '../ImportContentDrawer/ImportContentStore';

const styles = xcss({ width: '100%' });
export const i18n = defineMessages({
	singularTitle: {
		id: 'import-content.set-up-spaces-step.singular-title',
		defaultMessage: 'Set up space',
		description:
			'Title of a step in a flow that will help the user set up a Confluence space from imported data',
	},
	pluralTitle: {
		id: 'import-content.set-up-spaces-step.plural-title',
		defaultMessage: 'Set up spaces',
		description:
			'Title of a step in a flow that will help the user set up a multiple Confluence spaces from imported data',
	},
	singularDescription: {
		id: 'import-content.set-up-spaces-step.singular-description',
		defaultMessage:
			'Name the new Confluence space we’ll import your content to and set the permissions to determine who can access it.',
		description:
			'Short description explaining to the user what they have to do to properly set up their space for their Notion content they are importing into Confluence',
	},
	pluralDescription: {
		id: 'import-content.set-up-spaces-step.plural-description',
		defaultMessage:
			'Each Notion teamspace will become a Confluence space. Set the permissions to determine who can access them.',
		description:
			'Short description explaining to the user what they have to do to properly set up their spaces for their Notion content they are importing into Confluence',
	},
	spaceNameLabel: {
		id: 'import-content.set-up-spaces-step.space-name-label',
		defaultMessage: 'Name your space',
		description:
			'The label above a text field where the user is naming their Confluence space that they are importing Notion data into',
	},
	learnMoreLink: {
		id: 'import-content.set-up-spaces-step.learn-more-link',
		defaultMessage: 'Learn more about permissions',
		description:
			'Link text following the explanation of how a selected permission option works. Clicking it will bring the user to a help page for more details',
	},
	spaceNameError: {
		id: 'import-content.set-up-spaces-step.space-name-error',
		defaultMessage: 'Please enter a name for your space',
		description:
			'Error message displayed when the user tries to submit the form without entering a name for their Confluence space',
	},
	spaceNameTooLong: {
		id: 'import-content.set-up-spaces-step.space-name-too-long',
		defaultMessage: 'Space name must be 200 characters or less',
		description:
			'Error message displayed when the user tries to submit the form with a space name that is too long',
	},
});

const getImageSrc = (hasSubpages: boolean, isDarkMode: boolean) => {
	if (hasSubpages) {
		return isDarkMode ? PageWithSubpagesGraphicDark : PageWithSubpagesGraphic;
	} else {
		return isDarkMode ? EntireWorkspaceGraphicDark : EntireWorkspaceGraphic;
	}
};

export const SetUpSpacesStep: FC = () => {
	const context = useContext(ImportContentWorkflowContext);
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	const validate = useCallback((value: string | undefined) => {
		const trimmedValue = value ? value.trim() : '';

		if (trimmedValue.length < 1) {
			return 'TOO_SHORT';
		}
		if (trimmedValue.length === 200) {
			return 'LIMIT_REACHED';
		}
	}, []);

	return (
		<Grid
			templateColumns="6fr 1fr 5fr"
			gap="space.200"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ width: '800px' })}
		>
			<Stack space="space.300">
				<Stack space="space.200" alignInline="start">
					<Heading size="large">
						{context.hasSubpages ? (
							<FormattedMessage {...i18n.singularTitle} />
						) : (
							<FormattedMessage {...i18n.pluralTitle} />
						)}
					</Heading>
					<div>
						{context.hasSubpages ? (
							<FormattedMessage {...i18n.singularDescription} />
						) : (
							<FormattedMessage {...i18n.pluralDescription} />
						)}
					</div>

					<Link
						href="https://support.atlassian.com/jira-software-cloud/docs/faq-import-data-from-notion-into-confluence/#Can-I-restrict-private-content"
						target="_blank"
					>
						<FormattedMessage {...i18n.learnMoreLink} />
					</Link>
				</Stack>
				{context.hasSubpages ? (
					<Form<{ spaceName: string }> onSubmit={() => {}}>
						{({ formProps }) => (
							<form {...formProps}>
								<Stack xcss={styles}>
									<Field
										aria-required
										name="spaceName"
										label={<FormattedMessage {...i18n.spaceNameLabel} />}
										isRequired
										defaultValue=""
										validate={validate}
									>
										{({ fieldProps, error }) => (
											<Fragment>
												<TextField
													{...fieldProps}
													value={context.spaceName}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														context.setSpaceName(event.target.value);
														fieldProps.onChange(event.target.value);
													}}
													maxLength={200}
												/>
												{error === 'TOO_SHORT' && (
													<ErrorMessage>
														<FormattedMessage {...i18n.spaceNameError} />
													</ErrorMessage>
												)}

												{error === 'LIMIT_REACHED' && (
													<ErrorMessage>
														<FormattedMessage {...i18n.spaceNameTooLong} />
													</ErrorMessage>
												)}
											</Fragment>
										)}
									</Field>
								</Stack>
							</form>
						)}
					</Form>
				) : null}

				<PermissionsSelector />
			</Stack>

			<Box />
			<Image src={getImageSrc(context.hasSubpages, isDarkMode)} />
		</Grid>
	);
};
