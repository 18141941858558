/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values */
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage */
import React, { useContext, useEffect, useState, Fragment, useCallback } from 'react';
import type { FC, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import Button from '@atlaskit/button/new';
import Link from '@atlaskit/link';
import { Box, Flex, Grid, Stack, xcss, Inline } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Toggle from '@atlaskit/toggle';
import SectionMessage from '@atlaskit/section-message';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { ErrorMessage, Field } from '@atlaskit/form';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import OpenIcon from '@atlaskit/icon/glyph/open';
import Form from '@atlaskit/form/Form';

import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { isFirefox, isMSEdge } from '@confluence/browser-helper';

import { GetOrgAdminStatusQuery } from '../GetOrgAdminStatus.graphql';
import type {
	GetOrgAdminStatusQuery as GetOrgAdminStatusQueryType,
	GetOrgAdminStatusQueryVariables,
} from '../__types__/GetOrgAdminStatusQuery';
import { ImportContentWorkflowContext } from '../ImportContentDrawer/ImportContentStore';
import { UserDataPreview } from '../ImportContentDrawer/UserDataPreview';
import { NotionWorkspaceCard } from '../ImportContentDrawer/NotionOauth/NotionWorkspaceCard';
import { NotionLoginButton } from '../ImportContentDrawer/NotionOauth/NotionLoginButton';
import { translations } from '../ImportContentDrawer/NotionOauth/translations';
import { PermissionOptions } from '../PermissionOptions';

const validateToken = (value: string | undefined): string | undefined => {
	const trimmedValue = value ? value.trim() : '';

	if (trimmedValue.length === 0) {
		return 'TOO_SHORT';
	}

	if (!trimmedValue.startsWith('secret')) {
		return 'INVALID_PREFIX';
	}

	return undefined;
};

export type OauthMessage = {
	message: string;
	accessToken: string;
	workspaceName: string;
	workspaceIcon: string;
	ownerEmail: string;
	userCount: number;
};

const IntegrationTokenModal = () => {
	const [token, setToken] = useState('');
	const context = useContext(ImportContentWorkflowContext);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [tokenIsValid, setTokenIsValid] = useState(false);

	useEffect(() => {
		const validationResult = validateToken(token);

		if (validationResult) {
			setTokenIsValid(false);
			return;
		}

		setTokenIsValid(true);
	}, [token]);

	const validate = useCallback((value: string | undefined) => {
		return validateToken(value);
	}, []);

	return (
		<Modal onClose={() => context.setShouldRenderIntegrationTokenModal(false)}>
			<ModalHeader>
				<ModalTitle>
					<FormattedMessage {...translations.addAnIntegrationTokenButtonText} />
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Stack space="space.150">
					<FormattedMessage {...translations.integrationTokenModalDescription} />
					<ol>
						<li>
							<FormattedMessage {...translations.integrationTokenModalStepOne} />
							&nbsp;
							<Link href="https://notion.so">
								Notion
								<OpenIcon size="small" label="" />
							</Link>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepTwo}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepThree}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepFour}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepFive}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepSix}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepSeven}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
					{/* Negative margin here is needed to offset the default <ol> margin*/}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<Box xcss={xcss({ marginBlockEnd: 'space.negative.100' })}>
						<FormattedMessage {...translations.integrationTokenModalPermissions} />
					</Box>
					<ol>
						<li value={8}>
							<FormattedMessage
								{...translations.integrationTokenModalStepEight}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage {...translations.integrationTokenModalStepNine} />
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepTen}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage {...translations.integrationTokenModalStepEleven} />
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepTwelve}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
					{/* Negative margin here is needed to offset the default <ol> margin */}
					<Box
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss={xcss({
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
							marginBlockEnd: context.hasSubpages ? 'space.negative.400' : 'space.negative.100',
						})}
					>
						{!context.hasSubpages && (
							<FormattedMessage
								{...translations.integrationTokenModalRepeat}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						)}
					</Box>
					<ol>
						<li value={13}>
							<FormattedMessage
								{...translations.integrationTokenModalStepThirteen}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepFourteen}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.integrationTokenModalStepFifteen}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
					<FormattedMessage {...translations.integrationTokenModalPaste} />
					<Form<{ notionIntegrationToken: string }> onSubmit={() => {}}>
						{({ formProps }) => (
							<form {...formProps}>
								<Field
									name="notionIntegrationToken"
									label={<FormattedMessage {...translations.integrationTokenTextFieldLabel} />}
									validate={validate}
								>
									{({ fieldProps, error }) => (
										<Fragment>
											<Textfield
												{...fieldProps}
												placeholder={formatMessage(
													translations.integrationTokenTextFieldPlaceholder,
												)}
												onChange={(event: ChangeEvent<HTMLInputElement>) => {
													setToken(event.target.value);
													fieldProps.onChange(event.target.value);
												}}
											/>
											{error === 'TOO_SHORT' && (
												<ErrorMessage>
													{formatMessage(translations.accessTokenErrorTooShort)}
												</ErrorMessage>
											)}

											{error === 'INVALID_PREFIX' && (
												<ErrorMessage>
													{formatMessage(translations.accessTokenErrorInvalidPrefix)}
												</ErrorMessage>
											)}
										</Fragment>
									)}
								</Field>
							</form>
						)}
					</Form>
				</Stack>
			</ModalBody>
			<ModalFooter>
				<Button
					appearance="subtle"
					onClick={() => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'integrationTokenCancel',
								source: 'importContentTransferUsers',
							},
						}).fire();
						context.setShouldRenderIntegrationTokenModal(false);
					}}
				>
					<FormattedMessage {...translations.modalCancelButtonText} />
				</Button>
				<Button
					appearance="primary"
					isDisabled={!tokenIsValid}
					onClick={() => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'integrationTokenSubmit',
								source: 'importContentTransferUsers',
							},
						}).fire();
						context.setIntegrationToken(token);
						context.setShouldRenderIntegrationTokenModal(false);
						context.setShouldCopyPagePermissions(true);
					}}
				>
					<FormattedMessage {...translations.modalSubmitButtonText} />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const AccessTokenModal = () => {
	const accessTokenModalStepTwo =
		isFirefox() || isMSEdge()
			? translations.accessTokenModalStepTwoFirefoxEdge
			: translations.accessTokenModalStepTwo;
	const accessTokenModalStepThree = isMSEdge()
		? translations.accessTokenModalStepThreeEdge
		: translations.accessTokenModalStepThree;
	const accessTokenModalStepSix =
		isFirefox() || isMSEdge()
			? translations.accessTokenModalStepSixFirefoxEdge
			: translations.accessTokenModalStepSix;
	const accessTokenModalStepSeven =
		isFirefox() || isMSEdge()
			? translations.accessTokenModalStepSevenFirefoxEdge
			: translations.accessTokenModalStepSeven;

	const [token, setToken] = useState('');
	const context = useContext(ImportContentWorkflowContext);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Modal onClose={() => context.setShouldRenderAccessTokenModal(false)}>
			<ModalHeader>
				<ModalTitle>
					<FormattedMessage {...translations.addAnAccessTokenButtonText} />
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Stack space="space.200">
					<FormattedMessage {...translations.accessTokenModalDescription} />
					<ol>
						<li>
							<FormattedMessage
								{...translations.accessTokenModalStepOne}
								values={{
									a: (words: string) => (
										<Link href="http://notion.so" target="_blank">
											{words}
										</Link>
									),
								}}
							/>
						</li>

						<li>
							<FormattedMessage
								{...accessTokenModalStepTwo}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...accessTokenModalStepThree}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.accessTokenModalStepFour}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...translations.accessTokenModalStepFive}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...accessTokenModalStepSix}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...accessTokenModalStepSeven}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
					<FormattedMessage {...translations.accessTokenModalPaste} />
					<Field
						name="Notion access token"
						label={<FormattedMessage {...translations.accessTokenTextFieldLabel} />}
					>
						{({ fieldProps }) => (
							<Textfield
								{...fieldProps}
								placeholder={formatMessage(translations.accessTokenTextFieldPlaceholder)}
								onChange={(event: ChangeEvent<HTMLInputElement>) => setToken(event.target.value)}
							/>
						)}
					</Field>
				</Stack>
			</ModalBody>
			<ModalFooter>
				<Button
					appearance="subtle"
					onClick={() => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'accessTokenCancel',
								source: 'importContentTransferUsers',
							},
						}).fire();
						context.setShouldRenderAccessTokenModal(false);
					}}
				>
					<FormattedMessage {...translations.modalCancelButtonText} />
				</Button>
				<Button
					appearance="primary"
					isDisabled={!token}
					onClick={() => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'accessTokenSubmit',
								source: 'importContentTransferUsers',
							},
						}).fire();
						context.setAccessToken(token);
						context.setShouldRenderAccessTokenModal(false);
					}}
				>
					<FormattedMessage {...translations.modalSubmitButtonText} />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const getAddRemainingMembersText = (shouldAddRemainingMembers: boolean) => {
	if (shouldAddRemainingMembers) {
		return (
			<>
				<FormattedMessage {...translations.addRemainingMembersDescription} />
				<FormattedMessage
					{...translations.addRemainingMembersLicensingDescription}
					values={{
						a: (words: string) => (
							<Link
								href="https://support.atlassian.com/jira-software-cloud/docs/faq-import-data-from-notion-into-confluence/#What-happens-to-my-plan-if-I-select-Add-remaining-members-to-Confluence"
								target="_blank"
							>
								{words}
							</Link>
						),
					}}
				/>
			</>
		);
	}
	return <FormattedMessage {...translations.dontAddRemainingMembersDescription} />;
};

export const ConnectDataStep: FC = () => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId, orgId } = useSessionData();
	const [oauthData, setOauthData] = useState<OauthMessage | null>(null);
	const [oauthError, setOauthError] = useState(false);

	const notionOauth = useBooleanFeatureFlag('confluence.frontend.notion-oauth');

	const showAccessTokenPortion = useBooleanFeatureFlag(
		'confluence.frontend.show-notion-permissions-option',
	);

	// This needs to be explictly set to false when the FF is not on since the toggle option will not show.
	if (!showAccessTokenPortion) {
		context.setShouldCopyPagePermissions(false);
	}

	const { data } = useQuery<GetOrgAdminStatusQueryType, GetOrgAdminStatusQueryVariables>(
		GetOrgAdminStatusQuery,
		{
			variables: {
				principalId: `ari:cloud:identity::user/${userId}`,
				resourceId: `ari:cloud:platform::org/${orgId}`,
			},
		},
	);

	const isOrgAdmin = !!data?.permitted;

	useEffect(() => {
		context.setShouldAddRemainingMembers(isOrgAdmin);
		// We only want to set this when isOrgAdmin is updated from receiving GraphQL data.
		if (!context.shouldMapNotionMembers) {
			context.setShouldAddRemainingMembers(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOrgAdmin, context.shouldMapNotionMembers]);

	const loginToNotionButton = oauthData ? (
		<NotionWorkspaceCard
			icon={oauthData.workspaceIcon}
			name={oauthData.workspaceName}
			userCount={oauthData.userCount}
			setOauthData={setOauthData}
		/>
	) : (
		<NotionLoginButton setOauthData={setOauthData} setOauthError={setOauthError} />
	);

	const integrationTokenButton = context.integrationToken ? (
		<Button
			isSelected
			iconBefore={CheckIcon}
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'integrationTokenModalButtonReclick',
						source: 'importContentTransferUsers',
					},
				}).fire();
				context.setShouldRenderIntegrationTokenModal(true);
			}}
		>
			<FormattedMessage {...translations.integrationTokenAddedButtonText} />
		</Button>
	) : (
		<div>
			<Button
				onClick={() => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'clicked',
							actionSubject: 'integrationTokenModalButton',
							source: 'importContentTransferUsers',
						},
					}).fire();
					context.setShouldRenderIntegrationTokenModal(true);
				}}
				appearance="primary"
			>
				<FormattedMessage {...translations.addAnIntegrationTokenButtonText} />
			</Button>
			{oauthError && (
				<Inline
					space="space.050"
					xcss={xcss({
						marginTop: 'space.100',
						lineHeight: '14px',
					})}
				>
					<ErrorIcon
						size="small"
						label="icon for Notion authentication error"
						primaryColor={token('color.text.danger', '#AE2E24')}
					/>
					<Box
						color="color.text.danger"
						xcss={xcss({
							lineHeight: '14px',
							color: 'color.text.danger',
						})}
					>
						<FormattedMessage {...translations.notionConnectionError} />
					</Box>
				</Inline>
			)}
		</div>
	);

	const accessTokenButton = context.accessToken ? (
		<Button
			isSelected
			iconBefore={CheckIcon}
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'accessTokenModalButtonReclick',
						source: 'importContentTransferUsers',
					},
				}).fire();
				context.setShouldRenderAccessTokenModal(true);
			}}
		>
			<FormattedMessage {...translations.accessTokenAddedButtonText} />
		</Button>
	) : (
		<Button
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'accessTokenModalButton',
						source: 'importContentTransferUsers',
					},
				}).fire();
				context.setShouldRenderAccessTokenModal(true);
			}}
			appearance="primary"
		>
			<FormattedMessage {...translations.addAnAccessTokenButtonText} />
		</Button>
	);

	return (
		<Grid
			templateColumns="6fr 1fr 5fr"
			gap="space.200"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ width: '800px' })}
		>
			<Stack space="space.400" alignInline="start">
				<Flex gap="space.100" alignItems="baseline">
					<Heading size="large">
						<FormattedMessage {...translations.title} />
					</Heading>
				</Flex>

				<Stack space="space.150" alignInline="start">
					<Flex alignItems="center" gap="space.100">
						<Toggle
							isChecked={context.shouldMapNotionMembers}
							onChange={() => {
								const shouldMapNotionMembers = !context.shouldMapNotionMembers;

								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'shouldMapNotionMembersToggle',
										source: 'importContentTransferUsers',
										attributes: {
											shouldMapNotionMembers,
										},
									},
								}).fire();

								context.setShouldMapNotionMembers(shouldMapNotionMembers);

								if (!shouldMapNotionMembers) {
									context.setIntegrationToken('');
								}
							}}
						/>

						<b>
							<FormattedMessage {...translations.mapNotionMembersToggleText} />
						</b>
					</Flex>

					<div>
						{context.shouldMapNotionMembers ? (
							<FormattedMessage {...translations.mapNotionMembersDescriptionOne} />
						) : (
							<FormattedMessage {...translations.dontMapNotionMembersDescription} />
						)}
					</div>

					{context.shouldMapNotionMembers ? (
						<>{notionOauth && !oauthError ? loginToNotionButton : integrationTokenButton}</>
					) : (
						<SectionMessage>
							<FormattedMessage {...translations.dontMapNotionMembersInfoBox} />
						</SectionMessage>
					)}
				</Stack>

				{context.shouldRenderIntegrationTokenModal ? (
					<ModalTransition>
						<IntegrationTokenModal />
					</ModalTransition>
				) : null}

				{context.integrationToken ? (
					<Stack space="space.150">
						<Flex alignItems="center" gap="space.100">
							<Toggle
								isChecked={context.shouldAddRemainingMembers}
								isDisabled={!isOrgAdmin}
								onChange={() => {
									createAnalyticsEvent({
										type: 'sendUIEvent',
										data: {
											action: 'clicked',
											actionSubject: 'shouldAddRemainingMembersToggle',
											source: 'importContentTransferUsers',
											attributes: {
												shouldAddRemainingMembers: !context.shouldAddRemainingMembers,
											},
										},
									}).fire();
									context.setShouldAddRemainingMembers(!context.shouldAddRemainingMembers);
								}}
							/>

							<b>
								<FormattedMessage {...translations.addRemainingMembersToggleText} />
							</b>
						</Flex>

						<Stack space="space.150">
							{isOrgAdmin ? (
								getAddRemainingMembersText(context.shouldAddRemainingMembers)
							) : (
								<Stack space="space.150">
									<FormattedMessage {...translations.cantAddRemainingMembersDescription} />
									<FormattedMessage {...translations.dontAddRemainingMembersDescription} />
								</Stack>
							)}
						</Stack>
					</Stack>
				) : null}

				{context.integrationToken && showAccessTokenPortion ? (
					<Stack space="space.150">
						<Flex alignItems="center" gap="space.100">
							<Toggle
								isChecked={context.shouldCopyPagePermissions}
								onChange={() => {
									const shouldCopyPagePermissions = !context.shouldCopyPagePermissions;
									createAnalyticsEvent({
										type: 'sendUIEvent',
										data: {
											action: 'clicked',
											actionSubject: 'shouldCopyPagePermissionsToggle',
											source: 'importContentTransferUsers',
											attributes: {
												shouldCopyPagePermissions,
											},
										},
									}).fire();
									context.setShouldCopyPagePermissions(shouldCopyPagePermissions);
									if (!shouldCopyPagePermissions) {
										context.setAccessToken('');
										context.setSelectedPermissions(PermissionOptions.DEFAULT);
									} else {
										context.setSelectedPermissions(PermissionOptions.NOTION);
									}
								}}
							/>
							<b>
								<FormattedMessage {...translations.copyNotionPagePermissionsToggleText} />
							</b>
						</Flex>
						{context.shouldCopyPagePermissions ? (
							<FormattedMessage
								{...translations.copyNotionPagePermissionsDescription}
								values={{
									a: (words: string) => (
										<Link
											href="https://support.atlassian.com/jira-software-cloud/docs/faq-import-data-from-notion-into-confluence/#Can-I-restrict-private-content"
											target="_blank"
										>
											{words}
										</Link>
									),
								}}
							/>
						) : (
							<FormattedMessage {...translations.dontCopyNotionPagePermissionsDescription} />
						)}
						{context.shouldCopyPagePermissions ? (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							<Box xcss={xcss({ width: 'fit-content' })}>{accessTokenButton}</Box>
						) : null}
					</Stack>
				) : null}

				{context.shouldRenderAccessTokenModal ? (
					<ModalTransition>
						<AccessTokenModal />
					</ModalTransition>
				) : null}
			</Stack>

			<Box />

			<UserDataPreview
				isAuthenticated={context.integrationToken !== ''}
				importUserData={context.shouldMapNotionMembers}
				addRemainingMembers={context.shouldAddRemainingMembers}
				copyPagePermissions={context.shouldCopyPagePermissions}
				userCount={oauthData?.userCount}
				oauthError={oauthError}
			/>
		</Grid>
	);
};
