import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Lozenge from '@atlaskit/lozenge';
import { ButtonItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { useImportContentStore } from './ImportContentStore';

const i18n = defineMessages({
	buttonText: {
		id: 'import-content.button.text',
		defaultMessage: 'Import from other tools {betaLozenge}',
		description:
			'Text on the button that opens a user experience to import content into Confluence',
	},
	betaLozenge: {
		id: 'import-content.button.betaLozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge on the button indicating that the feature is in beta',
	},
});

type ImportContentButtonProps = {
	isNav4Enabled?: boolean;
};

export const ImportContentButton: FC<ImportContentButtonProps> = ({ isNav4Enabled }) => {
	const [, importContentActions] = useImportContentStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const ButtonComponent = isNav4Enabled ? MenuButtonItem : ButtonItem;

	return (
		<ButtonComponent
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						source: 'importContentButton',
					},
				}).fire();
				importContentActions.setShouldRenderModal(true);
				importContentActions.setShowImportContentModal(true);
			}}
		>
			<FormattedMessage
				{...i18n.buttonText}
				values={{
					betaLozenge: (
						<Lozenge appearance="new">
							<FormattedMessage {...i18n.betaLozenge} />
						</Lozenge>
					),
				}}
			/>
		</ButtonComponent>
	);
};
