import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Flex, xcss, Inline, Text } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import FileIcon from '@atlaskit/icon/glyph/file';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { IconButton } from '@atlaskit/button/new';
import { N20A, N70 } from '@atlaskit/theme/colors';

export const i18n = defineMessages({
	title: {
		id: 'import-content.upload-file-status-display.title',
		defaultMessage: 'Data file',
		description:
			'Title of the completed dropzone that shows a preview of the uploaded data file below it',
	},
	notionTitle: {
		id: 'import-content.upload-file-status-display.notion-title',
		defaultMessage: 'Notion data file',
		description:
			'Title of the completed dropzone that shows a preview of the uploaded Notion data file below it',
	},
	uploading: {
		id: 'import-content.upload-file-status-display.uploading',
		defaultMessage: 'You can proceed to the next step while your file is uploading.',
		description:
			'Text indicating that the selected file of the dropzone is still actively uploading and the user may proceed in the meantime',
	},
	uploaded: {
		id: 'import-content.upload-file-status-display.uploaded',
		defaultMessage: 'Uploaded',
		description: 'Short text indicating that the selected file of the dropzone has uploaded',
	},
});

type UploadFileStatusDisplayProps = {
	isSuccessful: boolean;
	fileNameDisplay: string;
	resetUploadStatus: () => void;
	isNotion: boolean;
};

export const UploadFileStatusDisplay = ({
	isSuccessful,
	fileNameDisplay,
	resetUploadStatus,
	isNotion,
}: UploadFileStatusDisplayProps) => {
	return (
		<div
			style={{
				backgroundColor: `${token('elevation.surface.sunken', N20A)}`,
				padding: `${token('space.200', '16px')}`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: 'max-content',
			}}
		>
			<Heading level="h200">
				{isNotion ? (
					<FormattedMessage {...i18n.notionTitle} />
				) : (
					<FormattedMessage {...i18n.title} />
				)}
			</Heading>
			<Flex
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					minHeight: token('space.1000', '80px'),
					marginTop: 'space.150',
					marginBottom: 'space.150',
					borderRadius: 'border.radius.100',
					background: token('elevation.surface', '#FFFFFF'),
					padding: 'space.200',
					boxShadow: 'elevation.shadow.raised',
				})}
			>
				<Flex gap="space.200">
					<Flex alignItems="center" justifyContent="center">
						<FileIcon
							size="medium"
							label="icon for Uploading File"
							primaryColor={token('color.icon.disabled', N70)}
						/>
					</Flex>
					<Flex
						alignItems="center"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss={xcss({
							wordWrap: 'break-word',
							whiteSpace: 'pre-wrap',
							wordBreak: 'break-word',
						})}
					>
						<Heading size="small">{fileNameDisplay}</Heading>
					</Flex>
				</Flex>
			</Flex>
			<Flex justifyContent="space-between">
				<Inline
					space="space.100"
					alignBlock="center"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({ fontWeight: '400' })}
				>
					{isSuccessful ? (
						<CheckIcon
							size="small"
							label="file succesfully uploaded"
							primaryColor={token('color.icon.disabled', N70)}
						/>
					) : (
						<Spinner size="small" interactionName="load" appearance="inherit" />
					)}
					<Text color="color.text.subtlest">
						{isSuccessful ? (
							<FormattedMessage {...i18n.uploaded} />
						) : (
							<FormattedMessage {...i18n.uploading} />
						)}
					</Text>
				</Inline>
				<IconButton
					appearance="subtle"
					icon={CrossIcon}
					label="icon to cancel upload"
					onClick={resetUploadStatus}
					spacing="compact"
				/>
			</Flex>
		</div>
	);
};
