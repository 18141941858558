import React, { useContext } from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { RadioGroup } from '@atlaskit/radio';
import { Grid, Stack, Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useBooleanFeatureFlag } from '@confluence/session-data';

import { DropzoneWrapper } from '../ImportContentDrawer/UploadFile/DropzoneWrapper';
import { ImportContentWorkflowContext } from '../ImportContentDrawer/ImportContentStore';
import { RunImportDevOnly } from '../ImportContentDrawer/UploadFile/RunImportDevOnly';

const i18n = defineMessages({
	title: {
		id: 'import-content.upload-file-step.title',
		defaultMessage: 'Upload Notion content',
		description:
			'Title of the step of the import-content flow that allows users to upload their Notion data to be imported into Confluence',
	},
	description: {
		id: 'import-content.upload-file-step.description',
		defaultMessage:
			'First you’ll need to generate and download an export file of the content you want to import. What are you importing?',
		description:
			"Short overview explaining to the user that they'll need to export their Notion content in order to import it into Confluence. More detailed instructions are below this text",
	},
	entireNotionWorkspace: {
		id: 'import-content.upload-file-step.entire-notion-workspace',
		defaultMessage: 'Entire Notion workspace',
		description:
			'Text next to a radio button indicating that the user is trying to import an entire Notion workspace into Confluence',
	},
	pageWithSubpages: {
		id: 'import-content.upload-file-step.page-with-subpages',
		defaultMessage: 'Selected page with subpages',
		description:
			'Text next to a radio button indicating that the user is trying to import a page containing subpages into Confluence',
	},
	detailedStepsTitle: {
		id: 'import-content.upload-file-step.detailed-steps-title',
		defaultMessage: 'To get an export file from Notion:',
		description:
			'Text before a bulleted list that will explain in detail how to get an export file from Notion',
	},
	stepOne: {
		id: 'import-content.upload-file-step.step-one',
		defaultMessage: 'Go to workspace settings',
		description:
			'First step in some instructions that instructs the user to navigate to their workspace settings within Notion',
	},
	stepTwo: {
		id: 'import-content.upload-file-step.step-two',
		defaultMessage: 'Select <b>Export all workspace content</b>',
		description:
			'Second step in some instructions that instructs the user to select "Export all workspace content" within Notion',
	},
	stepThree: {
		id: 'import-content.upload-file-step.step-three',
		defaultMessage: 'Under Export format, select <b>HTML</b>',
		description:
			'Third step in some instructions that instructs the user to select "HTML" within Notion',
	},
	stepFour: {
		id: 'import-content.upload-file-step.step-four',
		defaultMessage: 'Enable “Create folders for subpages”',
		description:
			'Fourth step in some instructions that instructs the user to enable "Create folders for subpages" within Notion',
	},
	stepFive: {
		id: 'import-content.upload-file-step.step-five',
		defaultMessage: 'Choose whether to export comments',
		description:
			'Fifth step in some instructions that instructs the user to make a choice on whether or not they wish to export comments within Notion',
	},
	stepSix: {
		id: 'import-content.upload-file-step.step-six',
		defaultMessage: 'Select <b>Export</b>',
		description:
			'Final step in some instructions that instructs the user to select "Export" within Notion',
	},
	stepOneSubpages: {
		id: 'import-content.upload-file-step.step-one-subpages',
		defaultMessage: 'Go to the page you want to export',
		description:
			'First step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepTwoSubpages: {
		id: 'import-content.upload-file-step.step-two-subpages',
		defaultMessage: 'Open the ••• menu in the top right',
		description:
			'Second step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepThreeSubpages: {
		id: 'import-content.upload-file-step.step-three-subpages',
		defaultMessage: 'Select <b>Export</b>',
		description:
			'Third step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepFourSubpages: {
		id: 'import-content.upload-file-step.step-four-subpages',
		defaultMessage: 'Under the Export format, select <b>HTML</b>',
		description:
			'Fourth step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepFiveSubpages: {
		id: 'import-content.upload-file-step.step-five-subpages',
		defaultMessage: 'Enable ”Include subpages“',
		description:
			'Fifth step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepSixSubpages: {
		id: 'import-content.upload-file-step.step-six-subpages',
		defaultMessage: 'Enable ”Create folders for subpages“',
		description:
			'Sixth step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepSevenSubpages: {
		id: 'import-content.upload-file-step.step-seven-subpages',
		defaultMessage: 'Choose whether to export comments',
		description:
			'Seventh step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	stepEightSubpages: {
		id: 'import-content.upload-file-step.step-eight-subpages',
		defaultMessage: 'Select <b>Export</b>',
		description:
			'Final step in some instructions that instructs the user how to upload a selected Notion page with subpages',
	},
	bottomInstuctions: {
		id: 'import-content.upload-file-step.bottom-instructions',
		defaultMessage: 'Once you have the file, upload it here to continue.',
		description:
			'Instructions below a bulleted list telling the user to upload their new file in the current flow once they have it',
	},
	bottomTip: {
		id: 'import-content.upload-file-step.bottom-tip',
		defaultMessage:
			'Tip: Have teammates move Private pages to a teamspace to include them in the export file. {learnMoreLink}',
		description:
			'Tip at the bottom of a set of instructions telling the user that it will help to move private pages to a teamspace if they wish to include them in the import flow',
	},
	learnMoreLink: {
		id: 'import-content.upload-file-step.learn-more-link',
		defaultMessage: 'Learn more',
		description:
			'Link following the text of import-content.upload-file-step.bottom-tip that brings the user to a help page',
	},
});

export const UploadFileStep: FC = () => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showRunImportDevForm = useBooleanFeatureFlag('confluence.frontend.show.importers.dev.form');

	if (showRunImportDevForm) {
		return <RunImportDevOnly />;
	}

	return (
		<Grid
			templateColumns="6fr 1fr 5fr"
			gap="space.200"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ width: '800px' })}
		>
			<Stack space="space.200">
				<Heading size="large">
					<FormattedMessage {...i18n.title} />
				</Heading>
				<FormattedMessage {...i18n.description} />
				<RadioGroup
					value={context.hasSubpages ? 'withSubpages' : 'withoutSubpages'}
					options={[
						{
							label: <FormattedMessage {...i18n.entireNotionWorkspace} />,
							value: 'withoutSubpages',
						},
						{
							label: <FormattedMessage {...i18n.pageWithSubpages} />,
							value: 'withSubpages',
						},
					]}
					onChange={(e) => {
						const hasSubpages = e.target.value === 'withSubpages';
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: 'hasSubpagesToggle',
								source: 'importContentUploadNotionContent',
								attributes: {
									hasSubpages,
								},
							},
						}).fire();
						context.setHasSubpages(hasSubpages);
						if (!hasSubpages) {
							context.setSpaceName('');
						}
					}}
				/>

				<FormattedMessage {...i18n.detailedStepsTitle} />
				{context.hasSubpages ? (
					<ol>
						<li>
							<FormattedMessage {...i18n.stepOneSubpages} />
						</li>
						<li>
							<FormattedMessage {...i18n.stepTwoSubpages} />
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepThreeSubpages}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepFourSubpages}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage {...i18n.stepFiveSubpages} />
						</li>
						<li>
							<FormattedMessage {...i18n.stepSixSubpages} />
						</li>
						<li>
							<FormattedMessage {...i18n.stepSevenSubpages} />
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepEightSubpages}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
				) : (
					<ol>
						<li>
							<FormattedMessage {...i18n.stepOne} />
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepTwo}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepThree}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
						<li>
							<FormattedMessage {...i18n.stepFour} />
						</li>
						<li>
							<FormattedMessage {...i18n.stepFive} />
						</li>
						<li>
							<FormattedMessage
								{...i18n.stepSix}
								values={{ b: (words: string) => <b>{words}</b> }}
							/>
						</li>
					</ol>
				)}
				<FormattedMessage {...i18n.bottomInstuctions} />
				{!context.hasSubpages && (
					<FormattedMessage
						{...i18n.bottomTip}
						values={{
							learnMoreLink: (
								<Link
									href="https://support.atlassian.com/jira-software-cloud/docs/import-data-from-notion-into-confluence/"
									target="_blank"
								>
									<FormattedMessage {...i18n.learnMoreLink} />
								</Link>
							),
						}}
					/>
				)}
			</Stack>
			<Box />
			<DropzoneWrapper isNotion />
		</Grid>
	);
};
